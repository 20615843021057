import { createUrlWithParams } from "common/utility";
import { APICore } from "./apiCore";

const api = new APICore();

function saveUser(params: any) {
	const baseUrl = "/api/User/Save";
	return api.create(`${baseUrl}`, params);
}

function getAllUser(params: {
	pageNumber: number;
	pageSize: number;
	query: string;
	// userId: number;
	// roleName: string;
	// roleIds: string;
	[x: string]: any;
}) {
	const baseUrl = "/api/User/GetAll";
	return api.get(`${baseUrl}`, params);
}

function ClientSignUp(params: any) {
	const baseUrl = "/api/User/ClientSignUp";
	return api.create(`${baseUrl}`, params);
}

function VerifyUserOtp(params: { otp: number }, token: string) {
	const baseUrl = "/api/User/VerifyOtp";
	return api.get(`${baseUrl}`, params, token);
}

function getUserDetails(params: { id: number | string }) {
	const baseUrl = "/api/User/Get";
	return api.get(`${baseUrl}`, params);
}

function getExternalUsers(params?: any) {
	const baseUrl = "/api/User/GetMyTeams";
	return api.get(`${baseUrl}`, params);
}

function getMyTeams(params?: any) {
	const baseUrl = "/api/User/GetMyTeams";
	return api.get(`${baseUrl}`, params);
}

function switchTeams(params?: any) {
	const baseUrl = "/api/User/SwitchTeam";
	return api.get(`${baseUrl}`, params);
}

function changeTeam(params?: any) {
	const baseUrl = "/api/User/ChangeTeam";
	return api.get(`${baseUrl}`, params);
}

function inviteUser(params: any) {
	const baseUrl = "/api/User/InviteUser";
	return api.create(`${baseUrl}`, params);
}

function invitePersonalUser(params: any) {
	const baseUrl = `/api/User/InvitePersonalUser?email=${params.Email}`;
	return api.create(`${baseUrl}`, {});
}

function setUserApproval(params: any) {
	const baseUrl = "/api/User/SetUserApproval";
	return api.get(`${baseUrl}`, params);
}

function userGetMoreDetails() {
	const baseUrl = "/api/User/GetMoreDetails";
	return api.get(`${baseUrl}`, {});
}

function getUserPermissions(params: any = {}) {
	const baseUrl = "/api/User/GetUserPermissions";
	return api.get(`${baseUrl}`, params);
}

function saveUserPermission(params: any) {
	const baseUrl = "/api/User/SaveUserPermission";
	return api.create(`${baseUrl}`, params);
}

function deleteUserPermission(data: any) {
	const baseUrl = "/api/User/DeleteUserPermission";
	return api.delete(`${baseUrl}`, data);
}

function userLogout() {
	const baseUrl = "/api/User/Logout";
	return api.get(`${baseUrl}`, {});
}

function addUserToTeam(params: any = {}) {
	const baseUrl = "/api/User/AddUserToTeam";
	return api.create(createUrlWithParams(baseUrl, params), {});
}

function IndependentSignUp(data: any) {
	const baseUrl = "/api/User/IndependentSignUp";
	return api.create(`${baseUrl}`, data);
}

function UserArchive(params: any = {}) {
	const baseUrl = createUrlWithParams("/api/User/UserArchive", params);
	return api.create(`${baseUrl}`, {});
}

const Generate2FaCode = (token?:string) => {
	const baseUrl = "/api/User/Generate2FaCode";
	return api.get(`${baseUrl}`,{}, token);
};

const Remove2FaCode = (params: any = {}) => {
	const baseUrl = "/api/User/Remove2FaCode";
	return api.get(`${baseUrl}`, {});
};

const Reset2FaCode = (params?: any)=>{
  const baseUrl = "/api/User/Generate2FaCode";
  return api.get(`${baseUrl}`, params);
}

const Verify2FaCode= (params: any)=>{
  const baseUrl = `/api/User/Verify2FaCode`;
  return api.get(`${baseUrl}`, params);
}


export {
	addUserToTeam, changeTeam, ClientSignUp, deleteUserPermission, Generate2FaCode, getAllUser, getExternalUsers,
	getMyTeams, getUserDetails, getUserPermissions, IndependentSignUp, invitePersonalUser, inviteUser, Remove2FaCode, Reset2FaCode, saveUser, saveUserPermission, setUserApproval, switchTeams, UserArchive, userGetMoreDetails, userLogout, Verify2FaCode, VerifyUserOtp
};

