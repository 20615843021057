// Import necessary classes from the Azure SDK
import { BlockBlobClient } from "@azure/storage-blob";
import React, { useEffect, useState } from "react";
import { containerName, sasToken, storageAccountName } from '../constants/AzureUpload';
import { me } from '../helpers/api/auth';
import { getYearMonthPath, sanitizeFileName } from '../utils/misc';


const BlobUpload = () => {
    const [file, setFile] = useState<File | null>(null);

    useEffect(() => {
        // Fetch user data and SAS token when component mounts
        const fetchUserData = async () => {
            try {                
                await me();
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };

        fetchUserData();
    }, []);  // Empty dependency array means this runs once on mount

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const uploadFile = async () => {
        if (!file) {
            alert("Please select a file first!");
            return;
        }

        try {
            const sanitizedFileName = sanitizeFileName(file.name);
            const yearMonthPath = getYearMonthPath();
            
            const storageAccountUrl = `https://${storageAccountName}.blob.core.windows.net`;
            const blobName = `${yearMonthPath}/${sanitizedFileName}`;
            
            const sasUrl = `${storageAccountUrl}/${containerName}/${blobName}?${sasToken}`;
            const blockBlobClient = new BlockBlobClient(sasUrl);

            const response = await blockBlobClient.uploadData(file, {
                blobHTTPHeaders: { 
                    blobContentType: file.type,
                    blobContentDisposition: `attachment; filename="${sanitizedFileName}"`
                },
                onProgress: (ev) => console.log(ev)
            });
            
            console.log("File uploaded successfully:", response);
            alert("File uploaded successfully!");
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Error uploading file.");
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileChange}/>
            <button onClick={uploadFile}>Upload File</button>
        </div>
    );
};

export default BlobUpload;
