import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AzureFileUploadModal from "common/Modals/AzureFileUploadModal";
import { IFile } from "interfaces";

const defaultValues = {
  Caption: "",
  FullFileUrl: "",
  Files: null,
  Type: "",
  Name: "",
};

const ImageCaptionModal = ({
  show,
  onClose,
  isUploading,
  multiple,
  HandleSave,
  acceptFiles = 'image/*, video/mp4',
  labelText = 'Upload Image/Video'
}: {
  show: boolean;
  onClose: () => void;
  isUploading: boolean;
  multiple: boolean;
  HandleSave: (e: any) => void;
  acceptFiles?: string;
  labelText?: string;
}) => {
  const [data, setData] = useState({
    ...defaultValues,
  });
  const [showAzureUploader, setShowAzureUploader] = useState(false);

  const handleChange = (evt: any) => {
    let { name, value } = evt.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onFileUploaded = (file: IFile) => {
    setData((prev) => ({
      ...prev,
      FullFileUrl: file.FullFileUrl,
      Files: [file],
      Type: file.Type,
      Name: file.Name,
    }));
  };

  const onModalDismiss = () => {
    setData({ ...defaultValues });
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onModalDismiss}
        size='sm'
        centered
        className='high-precendence-modal'
        backdropClassName='high-precendence-modal-backdrop'
      >
        <Modal.Header closeButton>
          <h5 className='modal-title'>File Upload</h5>
        </Modal.Header>
        <Modal.Body>
          <form name='caption-form' id='caption-form'>
            <Row>
              <Col md={12}>
                <div className="position-relative">
                  {data.FullFileUrl ? (
                    <>
                      {data.Type?.includes("video/") ? (
                        <video width='100%' height='300' controls>
                          <source src={data.FullFileUrl} type='video/mp4' />
                          Your browser does not support HTML video.
                        </video>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "300px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={data.FullFileUrl}
                            alt='Preview'
                            className='img-fluid mb-3'
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            onClick={() => setShowAzureUploader(true)}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className='mb-3 d-flex align-items-center justify-content-center fs-20 cursor-pointer'
                      style={{
                        minHeight: 150,
                        minWidth: 150,
                        border: "1px dashed gray",
                      }}
                      onClick={() => setShowAzureUploader(true)}
                    >
                      {labelText}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label htmlFor={"caption"}>Caption</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    id='caption'
                    name='Caption'
                    value={data.Caption}
                    onChange={handleChange}
                    disabled={isUploading}
                    placeholder={"Caption"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='d-flex justify-content-between align-items-baseline'>
              <div className='button-list'>
                <Button
                  variant='primary'
                  type='button'
                  disabled={isUploading}
                  className='px-2'
                  onClick={() => HandleSave(data)}
                >
                  {isUploading && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
                  Submit
                </Button>
                <Button
                  variant='outline-primary'
                  className='px-2'
                  onClick={onModalDismiss}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <AzureFileUploadModal
        show={showAzureUploader}
        setShow={setShowAzureUploader}
        onFileUploaded={onFileUploaded}
        acceptFiles={acceptFiles}
      />
    </>
  );
};

export default ImageCaptionModal;
