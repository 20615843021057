import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ImageCaptionModal from "./ImageCaptionModal";
import AzureFileUploadModal from "common/Modals/AzureFileUploadModal";
import { IFile } from "interfaces";

interface Props {
  files: { Id: number; FullFileUrl: string; Caption?: string }[];
  setFiles: (files: { Id: number; FullFileUrl: string; Caption?: string }[]) => void;
  labelText?: string;
  acceptFiles?: string;
}

const ImageGrid = ({ files = [], setFiles, labelText = 'Upload Image/Video', acceptFiles = 'image/*, video/mp4' }: Props) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAzureUploader, setShowAzureUploader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleSave = (data: any) => {
    setFiles([...files, {
      Id: data.LogoFileId,
      FullFileUrl: data.FullFileUrl,
      Caption: data.Caption
    }]);
    setShowUploadModal(false);
  };

  const handleAzureUpload = (file: IFile) => {
    setShowAzureUploader(false);
    setShowUploadModal(true);
    // Pre-fill the modal with Azure file info
    handleSave({
      LogoFileId: file.Id,
      FullFileUrl: file.FullFileUrl,
      Caption: ""
    });
  };

  return (
    <>
      <Row>
        {files.map((file, index) => (
          <Col key={index} xl={3} className="mb-3">
            <div className="position-relative">
              {file.Type?.includes("video/") ? (
                <video width="100%" height="200" controls>
                  <source src={file.FullFileUrl} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              ) : (
                <img
                  src={file.FullFileUrl}
                  alt=""
                  className="img-fluid"
                  style={{ height: "200px", width: "100%", objectFit: "cover" }}
                />
              )}
              <span className="position-absolute top-0 end-0 pt-1 pe-1">
                <i
                  className="bi bi-x-circle-fill text-danger cursor-pointer"
                  onClick={() => {
                    const newFiles = [...files];
                    newFiles.splice(index, 1);
                    setFiles(newFiles);
                  }}
                ></i>
              </span>
              {file.Caption && (
                <div className="bg-light p-2">
                  <small>{file.Caption}</small>
                </div>
              )}
            </div>
          </Col>
        ))}
        <Col xl={3}>
          <div
            className="mb-3 d-flex align-items-center justify-content-center fs-20 cursor-pointer"
            style={{
              height: "200px",
              width: "200px",
              overflow: "hidden",
              border: "2px dashed gray",
              textAlign: "center",
            }}
            onClick={() => setShowAzureUploader(true)}
          >
            {labelText}
          </div>
        </Col>
      </Row>

      <ImageCaptionModal
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        isUploading={isUploading}
        multiple={false}
        HandleSave={handleSave}
        acceptFiles={acceptFiles}
        labelText={labelText}
      />

      <AzureFileUploadModal
        show={showAzureUploader}
        setShow={setShowAzureUploader}
        onFileUploaded={handleAzureUpload}
      />
    </>
  );
};

export default ImageGrid;
