import { BlobServiceClient } from "@azure/storage-blob";
import { Box, LinearProgress, Typography } from "@mui/material";
import AppLoader from "components/AppLoader";
import { saveFile } from "helpers";
import { me } from 'helpers/api/';
import { useToast, useUploadFile, useUser } from "hooks";
import { IFile } from "interfaces";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import {
	containerName,
	sasToken,
	storageAccountName,
} from "../../constants/AzureUpload";
let blobServiceClient = new BlobServiceClient(
	`https://${storageAccountName}.blob.core.windows.net?${sasToken}`
);
const AzureFileUploadModal = ({
	show,
	setShow,
	locationId,
	onFileUploaded,
	tourLink,
	acceptFiles,
	cb = (a: any) => {},
	multiple = false
}: {
	show: boolean;
	setShow: (a: boolean) => void;
	locationId?: any;
	tourLink?: any;
	acceptFiles?: string;
	onFileUploaded?: (result: IFile | IFile[]) => void;
	cb?: (a: any) => void;
	multiple?: boolean;
}) => {
	const { showToast } = useToast();
	const [progress, setProgress] = useState<boolean>(false);
	const [file, setFile] = useState<any>(null);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [asureFileUrl, setAzureFileUrl] = useState("");
	const { user } = useUser();

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				await me();
				const token = localStorage.getItem('BlobSasToken') || '';
				blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net?${token}`);
			} catch (error) {
				console.error('Failed to fetch user data:', error);
			}
		};

		fetchUserData();
	}, [show]);

	const onHide = () => {
		setShow(false);
		setFile(null);
		setIsUploading(false);
		setUploadProgress(0);
	};
	const handleChange = (_file: any) => {
		setFile(multiple ? Array.from(_file) : _file);
	};
	const sanitizeFileName = (fileName: string) => {
		return fileName
			.replace(/\s+/g, "_") 
			.replace(/[^\w.-]/g, "") 
			.toLowerCase(); 
	};
	const uploadFile = async () => {
		if (!file) {
			showToast("error", "Please Select a file to upload.");
			return;
		}

		setIsUploading(true);
		setProgress(true);
		// Get a container client
		const containerClient = blobServiceClient.getContainerClient(containerName);
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0");
		const folderPath = `${user?.TeamId}/${year}/${month}/`;

		try {
			if (multiple && Array.isArray(file)) {
				// Handle multiple files
				const uploadPromises = file.map(async (singleFile) => {
					const sanitizedFileName = sanitizeFileName(singleFile.name);
					const blobClient = containerClient.getBlockBlobClient(folderPath + sanitizedFileName);

					await blobClient.uploadData(singleFile, {
						onProgress: (progress) => {
							const percentage = Math.round(
								(progress.loadedBytes * 100) / singleFile.size
							);
							setUploadProgress(percentage);
						},
					});

					const fileURL = blobClient?.url;
					const formData = new FormData();
					formData.append("FileUrl", fileURL);
					const tempFileType: any = "string";
					formData.append("Type", tempFileType);

					return await saveFile(formData);
				});

				const results = await Promise.all(uploadPromises);
				const successfulUploads = results.map(result => result.Data).filter(Boolean);

				if (successfulUploads.length > 0) {
					showToast("success", "Files uploaded successfully!");
					if (typeof onFileUploaded === 'function') {
						onFileUploaded(successfulUploads);
					}
				}
			} else {
				// Existing single file upload logic
				const sanitizedFileName = sanitizeFileName(file.name);
				const blobClient = containerClient.getBlockBlobClient(folderPath + sanitizedFileName);

				await blobClient.uploadData(file, {
					onProgress: (progress) => {
						const percentage = Math.round(
							(progress.loadedBytes * 100) / file.size
						);
						setUploadProgress(percentage);
					},
				});

				const fileURL = blobClient?.url;
				const formData = new FormData();
				formData.append("FileUrl", fileURL);
				const tempFileType: any = "string";
				formData.append("Type", tempFileType);

				const resfile = await saveFile(formData);
				
				if (resfile.Data) {
					showToast("success", "File uploaded successfully!");
					if (typeof onFileUploaded === 'function') {
						onFileUploaded(resfile.Data);
					}
				}
			}

			setShow(false);
			setFile(null);
			setProgress(false);
			onHide();
		} catch (error) {
			console.error("Error uploading file:", error);
			showToast("error", "Error uploading file.");
			setIsUploading(false);
			setProgress(false);
		}
	};
	const {} = useUploadFile({
		onSuccess: (fileData: any) => {
			setFile(fileData);
		},
	});

	const getFileTypes = () => {
		if (acceptFiles === "image/*") {
			return ["jpg", "jpeg", "png", "gif"];
		}
		return ["jpg", "png", "gif", "mp4", "zip", "pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "csv", "xml", "json", "svg", "webp"];
	};

	return (
		<Modal show={show} centered className="azure-file-upload-modal">
			<Modal.Header onHide={onHide} closeButton>
				<h4 className="modal-title">Upload</h4>
			</Modal.Header>
			<Modal.Body>
				<div className="mb-3">
					{file === null && !isUploading && (
						<FileUploader
							handleChange={handleChange}
							name="file"
							types={getFileTypes()}
							classes="custom-file-uploader"
							multiple={multiple}
							children={
								<Box
									sx={{
										border: "2px dashed #730020",
										padding: 2,
										borderRadius: 1,
										cursor: "pointer",
										backgroundColor: "#f9f9f9",
										width: "100%",
										alignItems: "center",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Button
										variant="primary"
										type="button"
										disabled={progress}
										className="mb-2"
									>
										Upload {multiple ? "Files" : "A File"}
									</Button>
									<Typography sx={{ width: "100%", textAlign: "center" }}>
										...Or drag and drop {multiple ? "files" : "a file"} here
									</Typography>
								</Box>
							}
						/>
					)}

					{file && !isUploading && (
						<Box
							sx={{
								width: "100%",
								mt: 2,
								border: "2px dashed #730020",
								p: 1,
								textAlign: "center",
							}}
						>
							<Typography sx={{ mb: 1 }}>
								<b>Selected File:-</b>
								{file?.name}
							</Typography>
							<Button onClick={() => setFile(null)} variant="secondary">
								Remove
							</Button>
							{/* <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography>{uploadProgress}%</Typography> */}
						</Box>
					)}

					{isUploading && (
						<Box
							sx={{
								width: "100%",
								mt: 2,
								border: "2px dashed #730020",
								p: 1,
								textAlign: "center",
							}}
						>
							<Typography sx={{ mb: 1 }}>
								<b>Uploading File:-</b>
								{file?.name}
							</Typography>
							<LinearProgress variant="determinate" value={uploadProgress} />
							<Typography>{uploadProgress}%</Typography>
						</Box>
					)}
				</div>

				{tourLink && (
					<div className="float-end">
						<a href={tourLink} target="_blank" rel="noreferrer">
							View Tour
						</a>
					</div>
				)}

				<div className="d-flex justify-content-between align-items-baseline">
					<div className="button-list">
						<Button
							variant="primary"
							type="button"
							disabled={progress}
							className="px-2"
							onClick={uploadFile}
						>
							{progress && (
								<span className="d-inline-block me-2">
									<AppLoader variant="light" />
								</span>
							)}
							Submit
						</Button>
						<Button
							variant="outline-primary"
							className="px-2"
							onClick={() => onHide()}
						>
							Cancel
						</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AzureFileUploadModal;
