import { Generate2FaCode, VerifyUserOtp } from "helpers/api/user";
import { useToast } from "hooks";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/linkedLogo.svg";
import "../../assets/scss/custom/components/_otp.scss";

const VerifyOTP = () => {
  const { state } = useLocation();
  const recievedOtp = state?.otp;
  const [inProgress, setInProgress] = useState(false);
  const { showToast, dissmisToast } = useToast();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [QRCode, setQRCode] = useState("");
	const [code, setCode] = useState("");
	const [show2FaQRCode, setShow2FaQRCode] = useState(false);
  const RenderTwoFaEnabledModal = async (token?:string) => {
    try {
      const response = await Generate2FaCode(token);
      if (response.StatusCode === 200) {
        // Set QRCode and code from response data
        setQRCode(response.Data.TwoFactorAuthFullFileUrl);
        setCode(response.Data.TwoFactorCode);
        setShow2FaQRCode(true);
      } else {
        showToast("error", "Failed to generate 2FA code. Please try again.");
      }
    } catch (error) {
      console.error("Error generating 2FA code:", error);
      showToast("error", "An error occurred while generating 2FA code.");
    }
		// return (
		// 	<TwoFaEnabledModal
		// 		show2FaQRCode={show2FaQRCode}
		// 		setShow2FaQRCode={setShow2FaQRCode}
		// 		QRCode={QRCode}
		// 		showCode={code}
		// 	/>
		// );
	};
    const onSubmit = async () => {
    const key = "VerifyOtp";
    if (+otp === +recievedOtp) {
      try {
        setInProgress(true);
        showToast("info", "Your request is being processed", key);
        const response = await VerifyUserOtp({ otp: +otp}, state?.token.toString());
        dissmisToast(key);
        if (response.StatusCode === 200) {
          showToast("success", response.Message);
         await RenderTwoFaEnabledModal(state?.token.toString());
          navigate("/registered",{state:{
            message:"Successfully Registered"}});
        } else {
          showToast("error", response.Message);
          navigate("/sign-up");
        }
        setInProgress(false);
      } catch (error: any) {
        dissmisToast(key);
        console.error("Error occurred:", error);
        showToast("error", error.message);
        setInProgress(false);
      }
    }
    else{
       showToast("error", "OTP doesn't match.", key);
    }
  };
  useEffect(() => {
    if (state === null) {
      navigate("/sign-up");
    }
  }, []);

  useEffect(() => {
    let timerId: any;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setDisableBtn(true);
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    setRunTimer((t) => !t);
  }, []);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  return (
    <>
      <div className="text-center d-flex align-items-center justify-content-center mt-5 ">
        <Card style={{ width: "50%" }}>
          <div className="d-flex flex-column align-items-center justify-content-center p-2 shadow rounded">
            <Link to="/" className="logo logo-light text-center">
              <span className="logo-lg">
                <img src={Logo} alt="" height="100" />
              </span>
            </Link>
            <div className="mt-1 mb-2 text-black" style={{ fontSize: "16px" }}>
              Verify Your Email
            </div>
            <div className=" mb-3 text-black" style={{ fontSize: "14px" }}>
              A 4 digit code has been sent to{" "}
              <span className="text-primary">{state?.email}</span> to verify
              your email
            </div>
            <div className="d-flex w-100 justify-content-center align-items-center otp mb-3">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props:any) => (
                  <div className="d-flex justify-content-center align-items-center">
                    <input
                      disabled={inProgress}
                      {...props}
                      style={{
                        width: "70%",
                        height: "50px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                )}
              />
            </div>
            {disableBtn ? (
              <div className="d-flex justify-content-center align-items-center">
                The OTP has expired.
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                The OTP will expire in {minutes}: {seconds}.
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center">
              Didn't receive the OTP?
              <span
                className="text-primary"
                style={disableBtn ? { fontWeight: 500 } : { fontWeight: 300 }}
              >
                Resend
              </span>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-2">
              <div className="button-list">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={disableBtn}
                  className="rounded-pill px-4 py-2"
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default VerifyOTP;
