import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
  const baseUrl = "/api/user/login";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

function changePassword(params:{oldPassword: string; newPassword: string;}) {
  const baseUrl="/api/User/ChangeOldPassword"
  return api.get(`${baseUrl}`, params);
}

async function me() {
    const baseUrl = "/api/User/Me"
    try {
        const response = await api.get(`${baseUrl}`, {});
        
        // Save BlobSasToken to localStorage if it exists in the response
        if (response.Data?.BlobSasToken) {
            // debugger;
            localStorage.setItem('BlobSasToken', response.Data.BlobSasToken);
        }
        
        return response;
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

function getImpersonate(params:{userId?: number; teamId?: number;}) {
    const baseUrl="/api/User/GetImpersonate"
    return api.get(`${baseUrl}`, params);
}

export { changePassword, forgotPassword, getImpersonate, login, logout, me, signup };

