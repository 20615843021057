import { APICore } from "./apiCore";

const api = new APICore();

// vendor
function getFiles(params: any) {
  const baseUrl = "/api/File/GetAll";
  return api.get(`${baseUrl}`, params);
}

function getFile(params: { id: number | string }) {
  const baseUrl = "/api/File/Get";
  return api.get(`${baseUrl}`, params);
}

function saveFile(params: FormData | String, config?: any) {
  const baseUrl = "/api/File/Save";
  return api.fileUpload(`${baseUrl}`, params, config);
}

function saveBase64(base64: any) {
  const baseUrl = "/api/File/SaveBase64";
  return api.create(`${baseUrl}`, base64);
}

function updateAnnotationData(fileId: any, annotationData: any) {
  const baseUrl = `/api/File/UpdateAnnotationData?id=${fileId}&annotationData=${annotationData}`;
  return api.create(`${baseUrl}`, {});
}

function deleteFile(id: string | number) {
  const baseUrl = "/api/File/Delete";
  return api.delete(`${baseUrl}?id=${id}`);
}

export {
  deleteFile, getFile, getFiles, saveBase64, saveFile, updateAnnotationData
};

